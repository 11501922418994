<template>
  <el-card shadow="hover" :body-style="{ padding: '20px' }">
    <div
      class="d-flex justify-content-between align-items-center"
      slot="header"
    >
      <h5>{{ $route.meta.title }}</h5>
      <div class="d-flex justify-content-end align-items-center">
        <el-input
          placeholder="关键词搜索"
          size="small"
          v-model="params.keyword"
        >
        </el-input>
      </div>
    </div>
    <div class="tab-nav">
      <a
        href="javascript:;"
        class="mr-20"
        :class="{ 'tab-active': !listType }"
        @click="listSwitch(false)"
        >评论我</a
      >
      <a
        href="javascript:;"
        :class="{ 'tab-active': listType }"
        @click="listSwitch(true)"
        >我评论</a
      >
    </div>
    <div v-loading="loading">
      <div class="ucenter-comment" v-for="(item, i) in lists" :key="item.id">
        <el-avatar
          size="medium"
          class="mr-10 ucenter-comment-avatar"
          :src="
            item.user.avatar
              ? viewImg(item.user.avatar)
              : require('../../../assets/images/userAvatar.png')
          "
        ></el-avatar>
        <div class="ucenter-comment-body">
          <div class="ucenter-comment-body-header">
            <div>
              <span class="ucenter-comment-bold"
                ><NickName v-model="item.user"></NickName
              ></span>
              <span class="text-gray-6 ml-20">{{
                $utils.contrastTime(item.create_time)
              }}</span>
              <span class="text-gray-6 ml-20" v-if="item.is_edit > 0">
                修改过
              </span>
            </div>
            <div class="d-flex align-items-center mt-10">
              <div class="pointer" @click="fav(item)">
                <icon type="like" size="14"></icon>
                <span class="text-gray-6 ml-10 mr-10 small">{{
                  item.fav
                }}</span>
              </div>
              <div class="pointer" @click="unfav(item)">
                <icon type="unlike" size="14"></icon>
                <span class="text-gray-6 ml-10 mr-10 small">{{
                  item.unfav
                }}</span>
              </div>
              <a href="javascript:;" class="cursor" @click="del(item, i)"
                >删除</a
              >
            </div>
          </div>
          <router-link
            :to="'/video/detail/' + item.video_id"
            target="_blank"
            class="ucenter-comment-body-subtitle"
          >
            评论了视频：{{ item.video.title }}
          </router-link>
          <div class="ucenter-comment-body-content">
            <span
              class="text-info"
              v-if="item.at_user_id && item.at_user_id != item.user_id"
            >
              @{{ item.atUser.nickname }}
            </span>
            <span v-html="$utils.html2content(item.content)"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-20" v-if="params.pages > 1">
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="params.page"
        :page-size="params.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      loading: false,
      category: [],
      lists: [],
      listType: false,
      searchTimer: null, //搜索计时器
      params: {
        user_id: 0,
        for_user_id: 0,
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
      detailTag: "",
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.params.category_id = this.$route.meta.category_id;
    this.detailTag = this.$route.meta.detailTag;
    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      if (this.listType) {
        this.params.user_id = this.user.id;
        delete this.params.for_user_id;
      } else {
        this.params.for_user_id = this.user.id;
        delete this.params.user_id;
      }

      this.$api.video.Comment.lists(this.params)
        .then((res) => {
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    //点赞
    fav(item) {
      console.log("f");
      this.$api.video.Comment.fav({
        video_id: item.video_id,
        comment_id: item.id,
      })
        .then((res) => {
          item.fav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点踩
    unfav(item) {
      this.$api.video.Comment.unfav({
        video_id: item.video_id,
        comment_id: item.id,
      })
        .then((res) => {
          item.unfav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listSwitch(type) {
      this.listType = type;
      this.getList();
    },
    del(item, i) {
      this.$utils.confirm(
        `确定要删除吗？本才做将删除该评论以及其下相关回复且不可逆，请谨慎操作"`,
        () => {
          let delMethod = this.$api.video.Comment.del;
          let params = {
            video_id: item.video_id,
            id: item.id,
          };
          if (item.user_id != this.user.id) {
            delMethod = this.$api.video.Comment.delByFor;
            params = {
              id: item.id,
            };
          }
          delMethod(params)
            .then((res) => {
              this.getList(this.params.page);
              this.$utils.success("删除成功");
            })
            .catch(() => {
              this.loading = false;
            });
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-nav {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.tab-active {
  color: #49a4ff;
}
</style>